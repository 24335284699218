
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
    reactive,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import RecepitVoucher from '@/components/Reports/RecepitVoucher.vue';
  import CustomerServiceFilter from '@/components/dropdown/CustomerServiceFilter.vue';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import { Field } from 'vee-validate';
  import Swal from 'sweetalert2/dist/sweetalert2.js';

  export default defineComponent({
    name: 'customer-service-statment-listing',
    components: {
      Datatable,
      RecepitVoucher,
      CustomerServiceFilter,
      Field,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();
      const loading = ref(false);
      const selectedRecord = ref([]);
      const selectedPaymentMethod = ref();
      const selectedStatus = ref();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(
          translate('financeCustomerServicePaymentListing'),
          [translate('finance')]
        );
        loading.value = true;
        await store.dispatch(Actions.GET_FINANCE_CUSTOMER_SERVICE_STATMENT);
        await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
          LookupNameIds.PAYMENT_METHOD,
        ]);
        loading.value = false;
        await store.dispatch(Actions.GET_ALL_FINANCE_CUSTOMER_SERVICE);
        await store.dispatch(Actions.GET_ALL_CUSTOMERS);
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_FINANCE_CUSTOMER_SERVICE_STATMENT_STORE);
        store.dispatch(Actions.RESET_FINANCE_INVOICE_STORE);
      });

      const tableHeader = ref([
        {
          name: '',
          key: 'checkbox',
          sortable: false,
        },
        {
          name: translate('customer Name'),
          key: 'customerName',
          sortable: true,
        },
        {
          name: translate('date'),
          key: 'date',
          sortable: true,
        },
        {
          name: translate('paid At'),
          key: 'paidAt',
          sortable: true,
        },
        {
          name: translate('service Type'),
          key: 'serviceType',
          sortable: true,
        },

        {
          name: translate('phone Number'),
          key: 'customerPhoneNumber',
          sortable: true,
        },
        {
          name: translate('service Fees'),
          key: 'priceBeforeDiscount',
          sortable: true,
        },
        {
          name: translate('discount'),
          key: 'discount',
          sortable: true,
        },
        {
          name: translate('fees after discount'),
          key: 'amount',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'status',
          sortable: true,
        },
        {
          name: translate('Payment Method'),
          key: 'paymentMethod',
          sortable: true,
        },

        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const excelHeader = reactive({
        Customer: 'customerName',
        Date: 'date',
        'Service Type': 'serviceType',
        'Phone Number': 'customerPhoneNumber',
        'Service Fees': 'priceBeforeDiscount',
        Discount: 'discount',
        'Fees After Discount': 'amount',
        status: 'status',
      });

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const searchService = async (query) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_CUSTOMER_SERVICE_FILTER, query);
        await store.dispatch(Actions.RESET_ALL_FINANCE_CUSTOMER_SERVICE);
        await store.dispatch(
          Actions.UPDATE_FINANCE_CUSTOMER_SERVICE_STATMENT_CURRENT_PAGE,
          1
        );
        await store.dispatch(Actions.GET_FINANCE_CUSTOMER_SERVICE_STATMENT);

        loading.value = false;
        await store.dispatch(
          Actions.GET_ALL_FINANCE_CUSTOMER_SERVICE,
          query.value
        );
      };
      const onReset = async (query) => {
        await store.dispatch(Actions.UPDATE_CUSTOMER_SERVICE_FILTER, query);
        await store.dispatch(Actions.RESET_ALL_FINANCE_CUSTOMER_SERVICE);
        loading.value = true;
        await store.dispatch(
          Actions.UPDATE_FINANCE_CUSTOMER_SERVICE_STATMENT_CURRENT_PAGE,
          1
        );
        await store.dispatch(Actions.GET_FINANCE_CUSTOMER_SERVICE_STATMENT);

        loading.value = false;
        await store.dispatch(Actions.GET_ALL_FINANCE_CUSTOMER_SERVICE);
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(
          Actions.UPDATE_FINANCE_CUSTOMER_SERVICE_STATMENT_CURRENT_PAGE,
          val
        );
        await store.dispatch(Actions.GET_FINANCE_CUSTOMER_SERVICE_STATMENT);
        loading.value = false;
      };

      const toggleStatusField = (record) => {
        console.log(selectedRecord.value);
        selectedPaymentMethod.value = record.paymentType;
        selectedStatus.value = false;
        if (selectedRecord.value.length === 0) {
          selectedStatus.value = false;
          return;
        }
        selectedRecord.value = [
          selectedRecord.value[selectedRecord.value.length - 1],
        ];
      };

      console.log(store.getters.allCustomerServiceStatmentsList);

      const excelData = computed(() => {
        return (
          store.getters.allCustomerServiceStatmentsList?.data?.map(
            (customerCredit) => {
              const status = customerCredit.status ? 'Paid' : 'Not Paid';
              return {
                ...customerCredit,
                status: status,
              };
            }
          ) || []
        );
      });
      const lookupsList = computed(() => store.getters.allLookupsList);

      const updatePaymentMethod = async () => {
        if (!selectedPaymentMethod.value) {
          Swal.fire({
            text: translate('Payment method is required'),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          return;
        }

        // Check if selectedStatus is false
        if (!selectedStatus.value) {
          Swal.fire({
            text: translate('Status is required'),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          return;
        }
        const payload = {
          status: selectedStatus.value,
          paymentMethod: selectedPaymentMethod.value,
        };
        loading.value = true;
        await store.dispatch(Actions.UPDATE_FINANCE_CUSTOMER_SERVICE_STATMENT, {
          id: selectedRecord.value[0],
          data: payload,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          loading.value = false;
        } else {
          Swal.fire({
            text: translate('Status Updated Successfully'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-success',
            },
          });
          await store.dispatch(Actions.GET_FINANCE_CUSTOMER_SERVICE_STATMENT);
          loading.value = false;
          selectedStatus.value = '';
          selectedPaymentMethod.value = '';
          selectedRecord.value = [];
        }
      };

      return {
        tableData: computed(() => store.getters.customerServiceStatmentsList),
        paymentMethodList: computed(() => {
          return lookupsList.value.filter(
            (lookup) => lookup.lookupNameId === LookupNameIds.PAYMENT_METHOD
          );
        }),
        tableHeader,
        excelData,
        excelHeader,
        translate,
        totalItems: computed(
          () => store.getters.customerServiceStatmentsListCount
        ),
        pageChanged,
        goTo,
        loading,
        can,
        searchService,
        onReset,
        selectedRecord,
        toggleStatusField,
        selectedPaymentMethod,
        selectedStatus,
        updatePaymentMethod,
      };
    },
  });
